import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AppEnvironemnt } from '../app.environment';

@Injectable({ providedIn: 'root' })
export class MasterRepo {
  DoctorDetails: any;
  DoctorAddressDetails: any;
  PanelDoctorDetails: any;
  PatientDetails: any;
  PatientAddressDetails: any;
  PatientPanelDetails: any;
  StaffDetails: any;
  StaffAddressDetails: any;
  StaffPanel: any;
  constructor(private http: HttpClient) {}

  private get apiUrl(): string {
    return AppEnvironemnt.apiServer;
  }

  importConfig(model: any) {
    return this.http.post(this.apiUrl + '/uploadFileMaster', model);
  }

  //doctor post from here
  postmaster(dataToSave, postUrl): Observable<any> {
    return this.http.post(this.apiUrl + postUrl, dataToSave);
  }

  postVaccination(vdata, vurl): Observable<any> {
    return this.http.post(this.apiUrl + vurl, vdata);
  }

  getStaffGeneralInformationList() {
    return this.http.get(this.apiUrl + '/getStaffGeneralInformationList');
  }

  getDoctorScheduleListByDateRange(fromDate, toDate, particularDate, doctorID) {
    return this.http.get(
      this.apiUrl +
        `/getDoctorScheduleTimeByDateRange/${fromDate}/${toDate}/${particularDate}/${doctorID}`
    );
  }

  getDoctorScheduleTimeList() {
    return this.http.get(this.apiUrl + `/getDoctorScheduleTimeList`);
  }

  getList(url: string) {
    return this.http.get(this.apiUrl + url);
  }
  getDepartmentList() {
    return this.http.get(this.apiUrl + '/getDepartmentList');
  }

  getDoctorCount(hospitalGID: string, branchIDCode: string): Observable<any> {
    const params = new HttpParams()
      .set('HospitalGID', hospitalGID)
      .set('BranchIDCode', branchIDCode);

    return this.http.get<any>(`${this.apiUrl}/getDoctorCount`, { params });
  }

  getPatientCount(hospitalGID: string, branchIDCode: string): Observable<any> {
    const params = new HttpParams()
      .set('HospitalGID', hospitalGID)
      .set('BranchIDCode', branchIDCode);
    return this.http.get(this.apiUrl + '/getPatientCount', { params });
  }
  getStaffCount(hospitalGID: string, branchIDCode: string): Observable<any> {
    const params = new HttpParams()
      .set('HospitalGID', hospitalGID)
      .set('BranchIDCode', branchIDCode);
    return this.http.get(this.apiUrl + '/getStaffCount', { params });
  }

  getBranchList() {
    return this.http.get(this.apiUrl + '/getBranchDetailsList');
  }
  getHospitalList() {
    return this.http.get(this.apiUrl + '/getHospitalList');
  }

  getPatientConsignmentList() {
    return this.http.get(this.apiUrl + '/getPatientHospitalList');
  }
  getDoctorConsignmentList() {
    return this.http.get(this.apiUrl + '/getDoctorHospitalList');
  }
  getDoctorConsignmentListAll() {
    return this.http.get(this.apiUrl + '/getDoctorHospitalConsignmentListAll');
  }
  getStaffHospitalConsignmentList() {
    return this.http.get(this.apiUrl + '/getStaffHospitalConsignmentList');
  }
  getServices() {
    return this.http.get(this.apiUrl + '/getServicesDetailsList');
  }
  getMasterInvestigationList() {
    return this.http.get(this.apiUrl + '/getMasterInvestigationList');
  }
  getNationalityList() {
    return this.http.get(this.apiUrl + '/getNationalityList');
  }

  getPatientDiagnosisList() {
    return this.http.get(this.apiUrl + '/getPatientDiagnosisList');
  }

  getStaffAddressByID() {
    return this.http.get(this.apiUrl + '/getStaffAddressByID');
  }

  getReligionList() {
    return this.http.get(this.apiUrl + '/getReligionList');
  }

  getMasterBloodGroupList() {
    return this.http.get(this.apiUrl + '/getMasterBloodGroupList');
  }

  getMasterDoctorSpecialtyList() {
    return this.http.get(this.apiUrl + '/getMasterDoctorSpecialtyList');
  }

  getMasterHospitalSpecialtyList() {
    return this.http.get(this.apiUrl + '/getHospitalSpecialtyList');
  }

  getMasterHospitalSpecialtyListAll() {
    return this.http.get(this.apiUrl + '/getHospitalSpecialtyListAll');
  }

  getStaffTypeList() {
    return this.http.get(this.apiUrl + '/getStaffTypeList');
  }

  //get doctor by id
  getDoctorbyId(id: any) {
    return this.http.get(
      this.apiUrl + '/getPatientsDemographicInformationByID/' + id
    );
  }

  getStaffTypeListAll() {
    return this.http.get(this.apiUrl + '/getStaffTypeListAll');
  }

  getCountryListForAnonymusUser() {
    return this.http.get(this.apiUrl + '/getCountryListForAll');
  }

  // nullToReturnZero(value: any) {
  //     if (value == "" || value == null || value == undefined || value === undefined || value == NaN) {
  //         return value = 0;
  //     }
  //     return value
  // }

  nullToReturnZero(value: any) {
    if (
      value == '' ||
      value == null ||
      value == undefined ||
      value === undefined ||
      Number.isNaN(value)
    ) {
      return (value = 0);
    }
    return value;
  }

  getCountryList() {
    return this.http.get(this.apiUrl + '/getCountryList');
  }

  getMasterHospitalType() {
    return this.http.get(this.apiUrl + '/getHospitalTypeList');
  }

  getMasterAppointmentType() {
    return this.http.get(this.apiUrl + '/getMasterAppointmentType');
  }

  getDoctorDemographicInformationList() {
    return this.http.get(this.apiUrl + '/getDoctorDemographicInformationList');
  }

  getPatientsDemographicInformationList() {
    return this.http.get(
      this.apiUrl + '/getPatientsDemographicInformationList'
    );
  }

  getPatientRelationList() {
    return this.http.get(this.apiUrl + '/getPatientRelationList');
  }

  getMasterAppointmentStatusList() {
    return this.http.get(this.apiUrl + '/getMasterAppointmentStatusList');
  }

  // for All List

  getNationalityListAll() {
    return this.http.get(this.apiUrl + '/getNationalityListAll');
  }

  getReligionListAll() {
    return this.http.get(this.apiUrl + '/getReligionListAll');
  }

  getMasterBloodGroupListAll() {
    return this.http.get(this.apiUrl + '/getMasterBloodGroupListAll');
  }

  getMasterDoctorSpecialtyListAll() {
    return this.http.get(this.apiUrl + '/getMasterDoctorSpecialtyListAll');
  }
  getCountryListAll() {
    return this.http.get(this.apiUrl + '/getCountryListAll');
  }

  getMasterHospitalTypeAll() {
    return this.http.get(this.apiUrl + '/getHospitalTypeListAll');
  }
  getMasterCurrencyTypeAll() {
    return this.http.get(this.apiUrl + '/getMasterCurrencyTypeListAll');
  }
  getMeasurementUnitAll() {
    return this.http.get(this.apiUrl + '/getMasterMeasurementUnitListAll');
  }
  getMasterAppointmentTypeAll() {
    return this.http.get(this.apiUrl + '/getMasterAppointmentTypeAll');
  }

  getDoctorDemographicInformationListAll() {
    return this.http.get(
      this.apiUrl + '/getDoctorDemographicInformationListAll'
    );
  }

  getPatientsDemographicInformationListAll() {
    return this.http.get(
      this.apiUrl + '/getPatientsDemographicInformationListAll'
    );
  }

  // Patient single record by id
  getSinglePateintbyid(id: any) {
    return this.http.get(
      this.apiUrl + '/getPatientsDemographicInformationByID/' + id
    );
  }

  getPatientRelationListAll() {
    return this.http.get(this.apiUrl + '/getPatientRelationListAll');
  }

  getMasterAppointmentStatusListAll() {
    return this.http.get(this.apiUrl + '/getMasterAppointmentStatusListAll');
  }

  getRolesListAll() {
    return this.http.get(this.apiUrl + '/getRolesListAll');
  }
  getStaffAddressList() {
    return this.http.get(this.apiUrl + '/getStaffAddressList');
  }

  // ***************************8
  getUsersList() {
    return this.http.get(this.apiUrl + '/getUsersList');
  }

  getRoleList() {
    return this.http.get(this.apiUrl + '/getAllRolesList');
  }

  getRolesList() {
    return this.http.get(this.apiUrl + '/getRolesList');
  }

  getHospitalListTable() {
    let res = { status: 'error', result: '' };
    let returnSubject: Subject<any> = new Subject();
    return this.http.get(this.apiUrl + '/getHospitalList');
  }

  getUserlListTable() {
    let res = { status: 'error', result: '' };
    let returnSubject: Subject<any> = new Subject();
    return this.http.get(this.apiUrl + '/getUsersList');
  }

  getDoctorListTable() {
    let res = { status: 'error', result: '' };
    let returnSubject: Subject<any> = new Subject();
    return this.http.get(this.apiUrl + '/getDoctorDemographicInformationList');
  }

  getCardNo(url: string) {
    return this.http.get(this.apiUrl + url);
  }
  getMaster(url: string) {
    return this.http.get(this.apiUrl + url);
  }


  postImageFile(image: File, folderPath:string): Observable<any> {
    const formData = new FormData();
    formData.append('image', image, image.name);
    return this.http.post<any>(
      `${this.apiUrl}/uploadDoctorImageFile/${folderPath}`,
      formData
    );
  }

  getListByParam(HospitalID: string, BranchID: string, DoctorID: string) {
    const params = new HttpParams()
      .set("HospitalID", HospitalID)
      .set("BranchID", BranchID)
      .set("DoctorID", DoctorID);
    return this.http.get<any>(`${this.apiUrl}/V1/getDoctorTimeSlotList/`, {params});
  }

  getListTwoParams(urls: string, PatientHospitalRecord: string, DoctorID: string) {
    let params = new HttpParams()
      .set("PatientHospitalRecord", PatientHospitalRecord)
      .set("DoctorID", DoctorID);    
    return this.http.get<any>(`${this.apiUrl}/${urls}`, { params });
  }

  getListThreeParams(urls: string, HospitalID: string, BranchID: string, DoctorID: string, Day: string) {
    let params = new HttpParams()
      .set("PatientHospitalRecord", HospitalID)
      .set("BranchID", BranchID)
      .set("DoctorID", DoctorID)
      .set("Day", Day);
  
    return this.http.get<any>(`${this.apiUrl}/${urls}`, { params });
  }
  

  // complaints getURL
  getComplaintswithclinicalExam(compliantsID) {
    return this.http.get(
      this.apiUrl + '/getMedicalDetailsClinicalExamination/' + compliantsID
    );
  }
}
